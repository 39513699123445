$arrow-size: 0.3em;

accordion-details {
	margin: 0 auto;
	min-height: 10rem;
	display: flex;
	// justify-content: center;
	flex-direction: column;

	details {
		height: var(--collapsed);
		overflow: hidden;
		transition: height 300ms cubic-bezier(0.4, 0.01, 0.165, 0.99);
		border-bottom: 1px solid $color-gray1;
	}
	details[open] {
		height: var(--expanded);
	}

	// Get rid of default arrows.
	summary { list-style: none; } // Spec
  summary::-webkit-details-marker { display: none; } // Chrome
  summary::before { display: none; } // Polyfill
  summary { cursor: pointer; }

	// Add our own arrow
	summary::after {
		border-right: 2px solid;
		border-bottom: 2px solid;
		content: "";
		float: right;
		width: $arrow-size;
		height: $arrow-size;
		position: relative;
		top: 0.4em;
		right: -0.5rem;
		transform-origin: center;
		transform: rotate(-45deg);
		transition: transform 250ms cubic-bezier(0.165, 0.84, 0.4, 1);
	}

	summary {
		padding: 0.4em 0.5em 0.4em 0;
		transition: color 200ms;
		outline: none;
	}
	summary.h3 {
		margin: 0;
	}
	summary:hover {
		transition-duration: 125ms;
	}
	summary:focus,
	summary:hover {
		color: $color-theme;
		outline: none;
	}
	details[open] summary::after {
		transform: rotate(45deg);
	}
	summary ~ * {
		padding-bottom: 0.75em;
	}
	.h2 {
		@media (max-width: $small) {
			font-size: 1.5rem;
		}
	}
}
