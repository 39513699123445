// Dimensions
$container-width: 45rem;
$border-radius: 0.5rem;
$container-padding: 1.25rem;

// Breakpoints. Important, must be em units.
$small: 50em;
$medium: 75em;
$large: 100em;

:root {
	--fontSize: 18px;
	--lineHeight: calc(var(--fontSize)*1.5);
	--baseline: calc(var(--lineHeight)/2);
	--columnGap: calc(var(--lineHeight)*1);
	--rowGap: var(--lineHeight);

	@media (min-width: $large ) {
		--columnGap: calc(var(--lineHeight)*1.5);
	}
}

// Grays
$color-white: hsl(0, 0%, 100%);
$color-gray00: #fafafa;
$color-gray0: #eee;
$color-gray1: #ccc;
$color-gray2: #999;
$color-gray3: #666;
$color-gray4: #444;
$color-black: hsl(0, 0%, 10%);

$color-blue: #00b2c2;
$color-blue1: #dff9fc;
$color-blue2: #7de8f1;
$color-blue3: #2dd3e2;
$color-blue4: #00b2c2;
$color-blue5: #C1E7E4;
$color-blue6: #A7E6EC;
$color-blue7: #96D7D3;

$color-green: #099990;
$color-green1: #dcf4f3;
$color-green2: #7bd6d0;
$color-green3: #4dc3bc;
$color-green4: #099990;

$color-red: #d94940;
$color-red2: #f9bfbc;
$color-red1: #ffe7e5;
$color-red3: #ff9f99;
$color-red3: #fc6f66;
$color-red4: #d94940;
$color-darkred: #852620;

$color-yellow: #ffcc22;
$color-yellow1: #fffbe6;
$color-yellow2: #ffef9c;
$color-yellow3: #ffe351;
$color-yellow4: #ffd600;

$color-peach: #f89e7c;
$color-darkpeach: #C4582F;
$color-peach1: #fff0eb;
$color-peach2: #ffcdba;
$color-peach3: #E87A50;

// Color definitions
$color-theme: $color-blue;
$color-theme-dark: #00838f;
$color-theme-light: #ccf0f3;
$color-text: $color-black;
