ls-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 1.5rem;
	justify-content: center;

	h3,
	h4 {
		margin-bottom: 0;
	}
}

.ls-list-story {
	justify-content: center;
	text-transform: uppercase;
}

.ls-list-story-taxonomy {
	justify-content: center;
	text-transform: uppercase;
}

ls-list[vertical] {
	padding: 0;
	flex-direction: column;
	align-items: flex-start;

	ls-list-item {
		margin: 0;
		border-bottom: 1px solid $color-gray1;
		width: 100%;
	}
	ls-list-item:last-child {
		border-bottom: none;
	}
}

.LinkBlock {
	display: flex;
	flex-flow: row nowrap;
	color: inherit;
	text-decoration: none;
	align-items: center;

	.Icon--large {
		height: 3rem;
	}
	&:hover {
		color: $color-text;
	}
	&:hover h3 {
		color: $color-theme-dark;
	}
	&:hover p {
		// color: $color-text;
	}
}

.LinkBlock-media {
	margin: 1rem 1rem 1rem 0;
}

.LinkBlock-body {
	max-width: 70%;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.LinkBlock-body h3 {
	margin-top: 0;
}

