.Breadcrumbs {
	list-style: none;
	display: flex;
	font-size: smaller;
	padding-left: 0;
	overflow: hidden;


	.img-stories {
			margin-right: 0.3rem;
		}
}

// Arrow between links
.Breadcrumbs li:not(:last-child)::after {
	content: '/';
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

@media (max-width: $small) {
	.Breadcrumbs .Nav-dropdownToggle {
		position: relative;
		right: 0.7rem;
		top: 0.1em;
	}
}
