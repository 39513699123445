html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	font-family: 'source-sans-pro', -apple-system, system-ui, segoe ui, sans-serif;
	font-size: var(--fontSize);
	line-height: 1.5;
	background: $color-white;
	color: $color-text;
	// vertical rhythm with 24px (16 * 1.5) grid. uncomment for debug.
	// background-image: linear-gradient(rgba(0, 170, 255, 0.4) 1px, transparent 1px);
	// background-size: 100% 1.5rem;
}

@media (min-width: $large) {
	html {
		font-size: 19px;
	}
}

body {
	overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	// links and small text inside headlines are black.
	small,
	a {
		color: $color-text;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
form,
hr {
	margin-top: 0;
	margin-bottom: 1rem;

	&[compact] {
		margin-bottom: 0.5rem;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	&[regular] {
		font-weight: 400;
	}
}

h1,
.h1 {
	font-size: (40/18) * 1rem;
	line-height: 1.1;
	margin-bottom: 2rem;
	font-weight: 700;
	text-wrap: pretty;

	@media (min-width: $medium) {
		font-size: (54/18) * 1rem;
	}

	small {
		font-size: 1em;
		font-weight: 400;
	}
}

h2,
.h2 {
	font-size: (32/18) * 1rem;
	font-weight: 700;
	line-height: 1.1;
	margin-top: 2rem;
	margin-bottom: 1rem;
	text-wrap: pretty;

	@media (min-width: $medium) {
		font-size: (40/18) * 1rem;
	}

	&[regular] strong {
		font-weight: 600;
	}
}

// Subheader
h3,
.h3 {
	margin-top: 2rem;
	font-size: (25/18) * 1rem;
	font-weight: 600;
	margin-bottom: 0rem;
	line-height: 1.2;

	&.regular {
		font-weight: 400;
	}

	img + & {
		margin-top: 0;
	}

	+ p > img {
		margin-top: 1rem;
	}
}

h4,
.h4 {
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 0;
	line-height: 1.2;
}

h5,
.h5 {
	font-size: 1rem;
	font-weight: 700;
}

.SmallType {
	font-size: (12/16) * 1rem;
	line-height: 1.3;
	color: $color-gray2;
}

a {
	color: $color-theme;
	transition: color 200ms;

	&:hover {
		color: $color-theme-dark;
		transition-duration: 125ms;
	}
}

// Use this when you want black links instead of blue.
.BlackLinks {
	a {
		color: inherit;
	}
}

.NoUnderlineLinks a {
	text-decoration: none;
}

.InvisibleLink {
	color: inherit;
	text-decoration: none;
}

figure {
	margin-left: 0;
	margin-right: 0;
}

iframe,
video,
img {
	max-width: 100%;
}

hr {
	border: 0;
	width: 100%;
	max-width: 6rem;
	background: $color-black;
	height: 2px;
}

.Site-main iframe {
	height: 100%;
}

iframe[height='315'] {
	min-height: 315px;
}

video-placeholder iframe[height='315'] {
	min-height: 0;
}

address {
	font-style: normal;
}

[regular] {
	font-weight: 400;
}

[blue],
[theme] {
	color: $color-theme;
}

[gray2] {
	color: $color-gray2;
}

[gray4] {
	color: $color-gray4;
}

.green,
[green] {
	color: $color-green;

	h4,
	p {
		color: initial;
	}
}

[peach] {
	color: $color-peach;
}
[backgroundblue] {
	background: $color-blue;
}

[red4] {
	color: $color-red4;
}

[nomargin] {
	margin: 0;
}

[margin-top] {
	margin-top: 1rem;
}
[padding] {
	padding-left: $container-padding;
	padding-right: $container-padding;
}

[underline] {
	text-decoration: underline;
}

[nodecoration] {
	text-decoration: none;
}

.u-hideText {
	display: none;
}

.u-onlySmall {
	@media (min-width: $small) {
		display: none;
	}
}

[lower] {
	text-transform: lowercase;
}

h1[compact] + h2 {
	margin-top: 0rem;
}

[strikethrough] {
	text-decoration: line-through;
}

[red] {
	color: $color-red;
}
[peach] {
	color: $color-peach;
}

[center] {
	text-align: center;
}

img[center] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
