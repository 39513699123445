[hidden] {
	display: none;
}

[push] {
	margin-bottom: 2rem;
}

[uppercase] {
	text-transform: uppercase;
}

[black] {
	color: $color-gray4;
}

[white] {
	color: $color-white;
}


/* Animation Blick */
@keyframes Loading {
	0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.LoadingAnimation {
	width: 2rem;
	height: 2rem;
	display: block;
	background-color: $color-theme;
	animation: Loading 1s infinite;
	margin-left: auto;
	margin-right: auto;
}
