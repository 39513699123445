// Print styles

@media print {
	:root {
		--fontSize: 12px;
	}

	/* Hide header and footer */
	.Site-header,
	.Site-footer,
	.u-hideprint {
		display: none;
	}
	.Site-main {
		margin-top: 1rem;
	}

	.Section {
		padding-bottom: 2rem;
	}

	/* Make sure text is black */
	h2[peach] {
		color: black;
	}
	.ServiceGallery {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1.25rem;

		&::after {
			content: '';
		}
	}

	/* Turn off flickity gallery and style it as thumbnails. */
	.Gallery::after {
		content: '';
	}

	.Gallery {
		opacity: 1;
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		> figure {
			width: auto;
			margin: 0;
		}
	}
}

