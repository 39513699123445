.SplitSection {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@media (min-width: $small) {
		flex-direction: row;
		overflow: visible;
	}
}

.SplitSection-body {
	// align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;

	@media (min-width: $small) {
		flex: 1;
		max-width: 50vw;
	}
}

.SplitSection-media {
	width: 100%;
	position: relative;
	order: -1;
	margin: auto auto 2rem;

	@media (min-width: $small) {
		width: 90%;
		margin-bottom: 0;
		padding: 0 1.25rem 1.25rem;
		max-width: 50%;
		min-height: 40vh;
		order: 2;
		display: flex;
	}

	img {
		width: 100%;
		object-fit: contain;

		// @media (min-width: $small) {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	height: 100%;
		// 	object-fit: contain;
		// 	object-position: 50% 20%;
		// }
	}

	iframe {
		margin: auto;
	}
}

.SplitSection-badge {
	width: 6rem;
	position: absolute;
	right: 4vw;
	bottom: -4.5rem;
	z-index: 5;
	display: none;

	@media (min-width: $small) {
		display: block;
	}
}

.Split {
	display: grid;
	grid-template-columns: 1fr auto;
}
.Split-right {
	align-self: flex-end;
}

@media (min-width: $small) {
	.SplitDesktop {
		display: grid;
		grid-template-columns: 1fr auto;
	}
	.SplitDesktop .Split-right {
		align-self: flex-end;
	}
}

.HomeSplitter {
	@media (min-width: $small) {
		display: grid;
		grid-template-columns: 40% 60%;
		gap: 1em;

		accordion-details {
			min-height: 26rem;
		}
	}
	@media (min-width: 66em) {
		grid-template-columns: 1fr 1fr;
		gap: 2em;
	}
}

.Wordbreak h3{
	word-break: break-all;
}
