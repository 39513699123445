.FooterMenus {
	@media (min-width: $small) {
		display: grid;
		grid-template-areas: 'one two three';
	}

	ul {
		list-style: none;
		padding-left: 0;
	}

	a {
		font-size: (14/16) * 1rem;
		color: $color-white;
		text-decoration: none;

		&[blue] {
			color: $color-blue;
		}
	}

	a:not([blue]):hover {
		color: $color-gray1;
	}

	a.is-active {
		text-decoration: underline;
	}
}

.FooterContact {
	grid-area: one;

	@media (min-width: $small) {
		margin-bottom: 0;
	}
}

.MetaLinks {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 22rem;
	grid-area: two;

	@media (min-width: $small) {
		margin-bottom: 0;
	}
}

.SocialLinks {
	grid-area: three;
	margin-left: auto;

	display: flex;
	flex-flow: row wrap;

	img {
		vertical-align: middle;
	}

	> li {
		margin-right: 1em;
	}

	@media (min-width: $small) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 1em;
		> li {
			margin-right: 0;
		}
	}
}

.LegalLinks {
	grid-area: three;
	margin-top: auto;

	@media (min-width: $small) {
		margin-left: auto;
		text-align: right;
	}

	a {
		color: $color-gray2;
	}
}
