.Gallery {

	position: relative;
	clear: both;

	// hide until loaded
	opacity: 0;
	transition: opacity 200ms 100ms;

	&.flickity-enabled {
		opacity: 1;
	}

	&::after {
		content: 'flickity';
		display: none; /* hide :after */
	}
}


.Gallery figure:not(.Card-media) {
	width: 100%;
	margin: 0 2rem 0 0;

	// Vertical align slides in middle.
	min-height: 100%;
	max-height: 80vh;
}

.Gallery img {
	display: block;
	width: 100%;
	object-fit: cover;
	object-position: center;
}

.Gallery figcaption {
	font-size: (12/16) * 1rem;
	text-align: right;
	margin-top: 0.5em;
	margin-right: 1em;
	color: $color-gray2;

	// Fade in when active.
	opacity: 0;
	transition: opacity 400ms ease-in-out;
}

.Gallery .is-selected figcaption {
	opacity: 1;
}

.Gallery .flickity-button {
	opacity: 0;
	transition: opacity 200ms;
}

.Gallery:hover .flickity-button {
	opacity: 1;
	transition-duration: 100ms;
}

.flickity-page-dots {
	/* max-width: 1305px;
	left: 50%;
	transform: translateX(-50%);
	text-align: right; */
}
@media (max-width: 768px) {
	.flickity-page-dots {
		bottom: -20px;
		margin-top: 50px;
		max-width: 1305px;
		left: 50%;
		transform: translateX(-50%);
		text-align: right;
	}
}

.flickity-page-dots .dot {
	margin: 0 0.25rem;
	background-color: transparent;
	border: 1px solid;
}
.flickity-page-dots .dot.is-selected {
	background-color: $color-text;
}

.is-flickity-fullscreen {
	.Site-main {
		z-index: 3;
	}
	.flickity-fullscreen-button .flickity-button-icon {
		color: $color-white;
	}
}

.flickity-enabled.is-fullscreen {
	// top on of map
	z-index: 10;

	figure {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.Gallery.is-fullscreen img {
	height: auto;
	min-height: 0;
}

.ThumbGallery {
	figure:not(.Card-media) {
		width: 80%;
		margin-right: 1px;

		@media (min-width: $small) {
			width: 70%;
		}
	}
	img {
		height: auto;
	}
	.Gallery.is-fullscreen figure {
		width: 90%;
	}
}

.ThumbGallery--mini {
	figure:not(.Card-media) {
		width: 350px;
		margin-right: 2px;

		@media (min-width: $small) {
			width: 350px;
		}
	}
}

// A bigger gallery meant to be full width
.Gallery--fancy {
	figure:not(.Card-media) {
		width: 90%;
		max-height: none;

		@media (min-width: $small) {
			width: 70%;
		}

		img {
			width: 100%;
			height: auto;
			transform: scale(0.9);
			transition: transform 500ms cubic-bezier(0.165, 0.84, 0.4, 1);
		}
		figcaption {
			text-align: left;
			opacity: 0;
			transition: opacity 250ms cubic-bezier(0.165, 0.84, 0.4, 1);
		}
	}
	figure.is-selected:not(.Card-media) {
		img {
			transform: scale(1);
		}
		figcaption {
			opacity: 1;
		}
	}
	.flickity-page-dots {
		width: calc(75% + 0.25rem); // same as figure
	}
}

.Gallery--mobileOnly {
	opacity: 1;

	@media (min-width: $small) {
		/* disable Flickity for large devices */
		&::after {
			content: '';
		}
		// and switch to normal layout

		&:has(.Card--space, .Card--spaceFeatured) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			> * {
				width: auto;
			}
		}
	}
}
.Gallery--gridImages {
	@media(min-width: $small) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 1rem;
			justify-items: center;
		}
	}
