.MeetingRoom-image {
	position: relative;
	margin-bottom: 0.5rem;
	background: gray;

	img {
		border-radius: 0.3rem;
		display: block;
		aspect-ratio: 321/214;
	}
}

.MeetingRoom-tag {
	display: inline-flex;
	align-items: center;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 0.625rem;
	position: absolute;
	bottom: 0.625rem;
	right: 0.625rem;
	padding: 8px;
	font-size: 0.875rem;
	font-weight: bold;
}

.EventSpace-footer,
.MeetingRoom-footer {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;

	h3 {
		font-weight: 500;
		margin-top: 0.25rem;
		span {
			font-size: 1rem;
			color: $color-gray2;
			font-weight: normal;
		}
	}
}

.EventSpace-address,
.MeetingRoom-address {
	display: inline-flex;
	margin-left: auto;
	gap: 1rem;

	img {
		vertical-align: middle;
	}
}

.MeetingRoom .Card-link:hover {
	color: $color-theme;
}

/* Hide the default < marker */
.details-with-dialog summary {
	display: flex;
	flex-flow: column;
}
.details-with-dialog summary::-webkit-details-marker {
	display: none;
}
.details-with-dialog[open] > summary {
	cursor: default;
}
/* Backdrop */
.details-with-dialog[open] > summary:before {
	content: ' ';
	background: rgba(0, 0, 0, 0.4);
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 999;
	@media (max-width: $small) {
	}
}
button[data-close-dialog] {
	background: url('/media/close-x.svg') center no-repeat;
	width: 3rem;
	height: 3rem;
	border: 0;
	text-indent: -10rem;
	overflow: hidden;
}
details-dialog {
	position: fixed;
	top: 2rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;
	max-height: 100vh;
	max-width: 100vw;
	width: 540px;
	overflow: auto;

	@media (max-width: 500px) {
		top: 0;
		min-height: 100vh;
	}
}
details-dialog button[data-close-dialog] {
	position: absolute;
	top: 0;
	right: 0;
}
details-dialog .Box {
	padding: 2.5rem 1.25rem 1rem;
	@media (max-width: 500px) {
		.Box {
			padding-bottom: 2rem;
		}
	}
}

.EventSpace {
	img,
	figure {
		margin-bottom: 0;
	}
}

.EventSpace-image {
	position: relative;
	margin-bottom: 0.5rem;
	background: gray;
	border-radius: 0.3rem;
}

.EventSpace-footer {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;

	h4 span {
		color: $color-gray2;
		font-weight: normal;
	}
	h4 {
		line-height: 1.25;
	}
}

// Inside overlays hide city + area inputs
details-dialog .Form--meetingRooms {
	.Form-city,
	.Form-area {
		display: none;
	}
}
