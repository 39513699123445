.Toc {
	// Hide the table of content on mobile version.
		display: none;

	// Desktop version
	@media (min-width: $medium) {
		display: block;
		position: fixed;
		z-index: 10;
		top: 6rem;
		right: 0.5rem;
		bottom: 0.5rem;
		max-width: 20vw;
		overflow: auto;
		padding: 1rem 0.25rem;
		box-shadow: 0 6px 20px rgba(0, 0, 0, 0.12);
		border-radius: $border-radius;
		border: 1px solid #eee;
		background: $color-white;
		font-size: (12/16) * 1rem;

		ul {
			// Remove default list bullets and spacing.
			padding-left: 0;
			list-style: none;
		}

		// Make links appear as "blocks"
		a {
			display: block;
			padding: 0.2em 0.5em;
			border-radius: 3px;
			text-decoration: none;
			color: $color-text;
			transition: all 150ms;

			// Tell it to add "..." at the end of the text
			// text-overflow: ellipsis;
			// // Make sure it does not wrap into multiple lines
			// white-space: nowrap;
			// // Constrain the width
			// width: 100%;
			// // Hide what would overflow the box.
			// overflow: hidden;
		}
		a:hover {
			background: #eee;
		}

		li.is-active > a {
			background: $color-green1;
		}

		// Level one
		 #TableOfContents > ul > li > a {
			 font-weight: bold;

		}

		// Level two
		ul ul {
			display: block;
			margin-left: 0.5em;
			margin-bottom: 0.75em;
		}

		ul ul li {
			margin-bottom: 0.25em;
		}
	}
}
