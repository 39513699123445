.Tags {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;

	> * + * {
		margin-right: 0.5rem;
	}
}

.Tag {
	display: inline-flex;
	align-items: center;
	min-height: (30/16) * 1rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	padding-top: 2px;
	white-space: nowrap;
	font-size: (12/16) * 1rem;
	text-transform: uppercase;
	font-weight: bold;
	border: 1px solid;
}

.Gallery-tag {
	display: inline-flex;
	align-items: center;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 0.625rem;
	position: absolute;
	bottom: 2rem;
	right: 0.625rem;
	padding: 10px;

	small {
		font-size: 10px;
		color: $color-black;
		margin-left: 2px;
	}
}

// presse page
.TagsType {
	font-size: 0.75rem;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	padding-top: 0.2rem;

}

.RemovePadding{
padding-left: 0;
}

.TagsNews {
	background-color: #f89e7c;
}
.TagsPodcast {
	background-color: #099990;
}
.TagsRadio {
	background-color: #ffcc22;
}
.TagsWhitepaper {
	background-color: #d94940;
}

// Inverted tags on cards
.Tag--invert {
	margin-bottom: 0.1rem;
	border-color: white;
	&.Tag {
		color: white;
	}
}

// Unique tags
.Tag--teamOffice {
	color: $color-theme;
	&.Tag--invert {
		background: $color-theme;
	}
}

.Tag--waitinglist {
	color: $color-gray2;
	&.Tag--invert {
		background: $color-gray2;
	}
}

.Tag--openSoon {
	color: $color-red;
	&.Tag--invert {
		background: $color-red;
	}
}

.Tag--eventSpace {
	color: $color-green;
	&.Tag--invert {
		background: $color-green;
	}
}

.Tag--exclusiveSpace {
	color: $color-peach;

	&.Tag--invert {
		background: $color-peach;
	}
}

.Tag--peach {
	color: $color-peach;
	&.Tag--invert {
		background: $color-peach;
	}
}

.Tag--red4 {
	color: $color-red4;
	&.Tag--invert {
		background: $color-red4;
	}
}

@media (max-width: 768px) {
	.Event-tag {
		display: none;
	}
}
