.MapList-map {
	margin-top: 1rem;
}
.MapList-menu button {
	border:none;
	background-color: $color-white;
	cursor: pointer;
}

@media (max-width: $small){
	.MapList-menu {
		display: flex;
		justify-content: flex-end;
		margin-right: 1rem;
	}
	.MapList-menu button.is-active path {
		stroke: $color-blue;
	}
	.is-hidden {
		display:none;
	}
	.MapList-map.is-hidden {
		display: block;
		height: 0;
		overflow: hidden;
	}
}

@media (min-width: $small) {
	.MapList {
		display: flex;
	}
	.MapList-list {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.MapList-map {
		flex: 0.5;
		background-color: $color-gray1;
	}
	.MapList-menu {
		display: none;
	}
}
