.Section {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 2rem;

	@media (min-width: $medium) {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	&.Section--nomargin {
		margin-bottom: 0;
	}
}

// For sections that need to stick to the header on small screens.
.Section--stickToHeader {
	margin-top: -2rem;
}

// Colored section need different spacings.
.Section--green1,
.Section--blue,
.Section--blue1,
.Section--blue2,
.Section--cyan,
.Section--yellow,
.Section--red2,
.Section--peach,
.Section--pink,
.Section--winterBlue,
.Section--paleYellow {
	//padding-top: 3rem;
	//padding-bottom: 2rem;
	//margin-bottom: 2rem;
}

.Section--blue {
	background-color: $color-blue6;

	&.Section--fancy {
		padding-top: 4rem;
		margin-bottom: 1rem;
		background-color: transparent;
		overflow: hidden;

		&::after {
			content: '';
			display: block;
			background-color: $color-blue1;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 65%;
			z-index: -1;
		}
	}
}
.Section--intro {
	margin-bottom: 0rem;
}

.Section--cyan {
	background-color: $color-blue5;

	a:not(.Button) {
		color: $color-black;
	}
}

.Section--yellow {
	background-color: $color-yellow;

	a:not(.Button) {
		color: $color-black;
	}
}

.Section--pink {
	background-color: $color-red1;
}

.Section--peach {
	background-color: $color-peach2;
}

.Section--paleYellow {
	background-color: $color-yellow1;
}

.Section--green1 {
	background-color: $color-green1;

	a:not(.Button) {
		color: $color-black;
	}
}

.Section--blue1 {
	background-color: $color-blue1;

	a:not(.Button) {
		color: $color-black;
	}
}
.Section--winterBlue {
	background-color: $color-blue7;
}

.Section--red2 {
	background-color: $color-red2;
	color: initial;

	h2 {
		color: $color-darkred;
	}

	ul {
		list-style: none;
		padding-left: 0;

		li {
			line-height: 1.2;
		}

		li::before {
			content: '•';
			font-size: 2rem;
			margin-right: 0.3rem;
			position: relative;
			top: 0.2rem;
			color: $color-red;
		}
	}

	a {
		color: $color-black;
	}
}
