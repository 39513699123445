.Box {
	padding: 1.5rem 1rem 1rem;
	background: $color-white;
	border-radius: $border-radius;
	box-shadow: 0px 0px 3rem rgba(0, 0, 0, 0.10);

	@media (min-width: $large) {
		padding: 2rem;
	}
	.details-with-dialog-header{
		h3,
		p {
			margin: 0;
		}
	}
}

.Box-img {
	border-radius: 50%;
	width: 7rem;
	height: 7rem;
	object-fit: cover;
}

.AnotherBox {
	display: flex;
	flex-flow: column nowrap;
	place-items: center;
	place-content: center;
	aspect-ratio: 1;

	h3 {
		margin: 1rem 0.5rem;
		color: $color-text;
		line-height: 1.2;
		text-align: center;

		@media (max-width: $small) {
			font-size: 1rem;
			font-size: 12px;
		}
	}

	img {
		@media (max-width: $small) {
			margin-top: 1rem;
			width: 2rem;
		}
	}

	&.blue {
		background: $color-blue2;
	}
	&.green {
		background: $color-green2;
	}
	&.red {
		background: $color-red2;
	}
}
