.Card {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	position: relative;
	margin-bottom: 1rem;
}

.Card-link,
.Card-wrapper {
	display: flex;
	flex-flow: column nowrap;
	flex-grow: 1;
	color: $color-black;
	width: 100%;
}

.Card-link img {
	width: 100%;
}

.Card-section {
	display: flex;
	flex-direction: row;
	margin-left: 2rem;
	flex-wrap: wrap;
}

.job-schedule {
	color: #999999;
}

.type-operations {
	background-color: #d94940;
}

.type-IT {
	background-color: #111111;
}

.type-finance {
	background-color: #099990;
}

.type-HR {
	background-color: #ffcc22;
}

.type-marketing {
	background-color: #f89e7c;
}

.type-sales {
	background-color: #00b2c2;
}

.Card-link {
	text-decoration: none;
	transition: border-color 200ms;

	.Card-title {
		transition: color 200ms;
	}

	&:hover,
	&:hover .Card-title {
		transition-duration: 125ms;
	}

	&:hover {
		border-color: $color-theme-dark;

		.Card-title {
			color: $color-theme;
		}
	}
}

.Card-media {
	width: 100%;
	max-width: 20rem;
	margin: 0 auto 1rem;
	text-align: center;
}

.Card-media img,
.Card-media svg {
	display: block;
	width: 100%;
	object-fit: cover;
	height: auto;
}
.Card-media img {
	aspect-ratio: 1920/1280;
}

.Card--city .Card-media img,
.Card--city .Card-media svg {
	aspect-ratio: 0;
}
.Card-media .Card--aspectRatio {
	aspect-ratio: 0;
}

.Card-body {
	flex-grow: 1;
}

.Card-title {
	margin-top: 0;
	margin-bottom: 0rem;
	// transition: color 125ms;

	small {
		font-size: 1em;
		font-weight: normal;
		color: $color-gray3;
	}

	.Split & {
		margin-right: 0.5rem;
	}
}

.Card ul {
	padding-left: 1rem;
	font-size: (14/16 * 1rem);
}

.Card-info {
	margin-bottom: 1rem;

	p {
		margin-bottom: 0;
	}
}

.Card-price {
	font-size: (14/16) * 1rem;
}
.Card-single {
	font-size: (15/16) * 1rem;
}
.Card-price {
	font-size: (18/16) * 1rem;
}

.Card--blog {
	.Card-media {
		max-width: none;
	}
	.Card-title {
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}
	.Card-link:hover h4 {
		color: $color-green4;
	}

	@media (min-width: $small) {
		.Card-media {
			flex-shrink: 0;
		}
		.Card-body {
			display: flex;
			flex-flow: column nowrap;

			h3 {
				margin-top: 0;
			}
		}
		.Card-footer:last-child {
			margin-top: auto;
		}
	}
}

.Card--taxonomy {
	h3 {
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}

	@media (min-width: $small) {
		.Card-link {
			flex-direction: row;
			margin-top: 1rem;
		}

		.Card-link {
			flex-direction: row;
		}

		.Card-media {
			flex-basis: 40%;
			flex-shrink: 0;
			margin-right: 1rem;
		}
	}
}

.Card .Tags {
	position: absolute;
	top: 0.5rem;
	right: -1rem;
	font-size: small;
	margin-bottom: 0;
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
}

.Card-footer {
	display: flex;
	flex-direction: row;
	align-items: center;

	span {
		margin-left: auto;
	}

	p {
		font-size: small;
		flex-grow: 1;
		margin-bottom: 0;
	}
}

.Card--horizontal {
	.Card-link {
		flex-direction: row;
		flex-wrap: wrap;
	}
	.Card-media {
		order: 2;
		margin: 0;
	}
	.Card-body {
		order: 1;
		flex-grow: 0;
	}
}

.Card--product {
	&::after {
		content: '';
		display: block;
		width: 4rem;
		height: 2px;
		background-color: var(--theme);
		margin-top: 1rem;
	}

	.Card-title {
		margin-bottom: 0.5rem;
	}

	.Card-link:hover p {
		color: initial;
	}
}

.Card--city,
.Card--product {
	align-items: center;
	text-align: center;

	.Card-media {
		max-width: none;
		/* max-width: 70%; */
	}

	.Card-link {
		background: none;

		&:hover p {
			color: initial;
		}
	}

}

.Card--cityMini {
	margin-bottom: 1rem;

	.Card-media {
		margin: auto;
		width: 2rem;

		@media (min-width: $small) {
			width: 3rem;
		}
	}
	.Card-title {
		font-size: 1rem;
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
}

.Card--category {
	.Card-link,
	.Card-wrapper {
		padding: 0.5rem 0.7rem;
		justify-content: center;
		border-bottom: 2px solid transparent;
	}

	.Card-link.is-active,
	.Card-wrapper.is-active {
		color: $color-black;
		text-decoration: none;
		border-bottom-color: black;
	}

	h6 {
		font-weight: normal;
		font-size: (14/16 * 1rem);
		// letter-spacing: 0.03em;
	}
}

.Card--space {
	.Card-media {
		max-width: none;
	}

	.Card-link {
		background: none;
		border: none;
		padding: 0;
	}

	.TagsType img {
		margin-right: 0.3rem;
	}
}

.Card--spaceFeatured {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	background: white;
	border-radius: $border-radius;
	// height: 100%;

	.Card-media {
		padding: 1em 1em 0;
		max-width: none;

		img {
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50%;
		}
	}

	.Card-body {
		margin: 0 1em 1em;
	}
}

[data-flickity] .Card {
	width: 90%;
	margin-right: 1.5rem;
	margin-bottom: 0;

	&.Card--press {
		p:last-of-type {
			margin: 0 1rem 3rem;
		}
	}
}



@media (min-width: $small) {
	[data-flickity] .Card {
		width: 21rem; // wide enough for "Checkpoint Charlie" to fit

		.Card-media img {
			min-height: 10rem;
		}
	}
}

.Card .Split {
	line-height: 1;
	+ .Split {
		margin-top: 0.5rem;
	}
}

// Dirty hack to hide price and sqm
r-cell[span='6'] .Card-price,
r-cell[span='6'] .Split[gray2] {
	/* display: none; */
}

// Card SVG animations
.Card--city,
.Card--product {
	// transform: translate3d(0, 0, 0);
	// backface-visibility: hidden;
	// perspective: 1000px;
	#HalfCircle {
		transition: transform 800ms cubic-bezier(0.465, 0.183, 0.153, 0.946), fill 800ms;
		transform-origin: center bottom;
	}
	#LLines,
	#RLines,
	#Lamp path {
		transition: transform 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946), fill 800ms;
		transform-origin: center bottom;
	}
	#Lamp #Vector_5,
	#Lamp #Vector_6,
	#Lamp #Vector_7 {
		transform-origin: top center;
	}
	.Card-link:hover {
		#HalfCircle {
			transform: scale(0.5);
			transition-duration: 300ms;
		}
		#office-s #Lamp {
			animation: swing 400ms infinite;
			transform-origin: top center;
		}
		#Chat1 {
			animation: swing 400ms infinite;
			transform-origin: top center;
		}
		#Pencil,
		#Cloud1,
		#Cloud2,
		#Logo {
			animation: shake 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000px;
			// fill: $color-yellow;
		}
		#Cloud2 {
			animation-delay: 200ms;
			animation-duration: 10s;
		}
		#LLines {
			transform: scale(1.1);
		}
		#RLines {
			transform: scale(1.1);
		}
		#Building #Vector_3,
		#Building #Vector_8,
		#Building #Vector_10 {
			animation: blink 500ms infinite;
		}
		#Building #Vector_8 {
			animation-delay: 50ms;
		}
		#Building #Vector_10 {
			animation-delay: 100ms;
		}
		#Lamp #Vector_5,
		#Lamp #Vector_6,
		#Lamp #Vector_7 {
			transform: translateY(0.1rem) scale(1.02);
		}
	}
}

.Card-summary {
	font-size: (13/16) * 1rem;
}

.Card--press {
	background: white;
	border-radius: $border-radius;

	.TagsType {
		flex-grow: 0;
	}
	.Card-link {
		border: 1px solid transparent;
		padding: 1rem;
		border-radius: 0.5em;
		transition: border 150ms;
		box-shadow: 0 3px 7px 1px hsla(0, 0%, 0%, 0.1);

		&:hover {
			border-color: $color-theme;
			.Card-action {
				color: $color-theme;
			}
		}
	}
	.Card-body {
		h3 {
			margin-top: 1rem;
		}
	}
	.Card-title {
		margin-top: 1rem;
		margin-bottom: 2rem;
	}
	.Card-footer:last-of-type {
		margin-top: auto;
	}
	.Author {
		margin: 0 auto 0 0;
	}
	.Card-action path {
		transition: fill 150ms;
		fill: currentColor;
	}
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes swing {
	0% {
		transform: rotate(-2deg);
	}
	50% {
		transform: rotate(2deg);
	}
	100% {
		transform: rotate(-2deg);
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

.Card-body {
	display: flex;
	flex-direction: column;
}

.Card-action {
	margin-top: auto;
	padding-top: 1em;
	text-align: center;
	color: $color-theme;
	transition: transform 500ms cubic-bezier(0.25, 1.4, 0.18, 1);
}

.Card a:hover .Card-action {
	transform: translateX(5px);
}

.Card--center {
	text-align: center;
	.Card-title + p {
		margin-top: 1rem;
	}
}

.Griddy {
	display: flex;
	flex-flow: row wrap;
	gap: 1rem 0;
	list-style: none;
	padding: 0 !important;
	justify-content: center;
	margin: 1rem 0;

	> li {
		display: flex;
		flex-flow: column nowrap;
		width: 25%;
		img {
			height: 2rem;
		}
		span:first-of-type {
			font-weight: bold;
			font-size: 18px;
			margin: 0.5rem 0 0;
			line-height: 1.1;
		}
		span:last-child {
			// text-transform: capitalize;
		}
	}
}
