.ServiceBox {
	width: 100%;
	border: 2px solid $color-green;
	border-radius: 1rem;
	padding-bottom: 1rem;

	h3 {
		margin-top: 0;
		padding: 1rem 1rem 1rem;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		background: $color-green1;
		margin-bottom: 0.5rem;
		text-align: center;
	}

	article {
		display: flex;
		align-items: center;
		max-width: 30rem;
		margin: auto;
		padding: 0.7rem 1.25rem;

		h4 {
			font-weight: normal;
			margin-bottom: 0;
		}
		p {
			text-align: left;
			line-height: 1.2;
		}
	}

	// hide things only used for the details version
	> img,
	> p,
	article p,
	.RoundArrowButton {
		display: none;
	}
}

.ServiceBox--event {
	padding: 0;
}

.ServiceBox--event hr {
	background-color: #099990;
	margin-top: 15px;
	height: 2px;
}

.ServiceBox--event article p {
	display: block;
}

.ServiceBox--event article h4 {
	text-align: left;
}

// Enable Flickity by default
.ServiceGallery::after {
	content: 'flickity';
	display: none;
}

// Replace Flickity for large devices with a 2x2 grid
@media (min-width: 1040px) {
	.ServiceGallery {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;

		&::after {
			content: '';
		}
	}
}

@media (min-width: 1440px) {
	.ServiceGallery:not(.Grid--sidebar .ServiceGallery) {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (max-width: 992px) {
	.ServiceBox--event {
		width: 100%;
	}
}

.ServiceGallery--details .ServiceBox {
	// show things again
	> img,
	> p,
	article p,
	.RoundArrowButton {
		display: block;
	}

	h4 {
		font-weight: 700;
	}

	position: relative;
	margin-top: 5rem;
	margin-bottom: 10rem;

	@media (max-width: $small) {
		&:last-of-type {
			margin-bottom: 0rem;
		}
	}

	@media (min-width: $small) {
		margin-top: 5rem;
		margin-bottom: 3rem;
	}

	article {
		flex-flow: column;
		@media (max-width: $small) {
			display: none;
		}
	}

	> img:first-child {
		display: block;
		height: 10rem;
		margin: -8rem auto -2rem;
		position: relative;
		z-index: -1;
	}

	.Icon {
		margin-bottom: 0.5rem;
	}

	.RoundArrowButton {
		position: absolute;
		bottom: -1rem;
		left: 50%;
		transform: translateX(-50%);
		transform-origin: center center;

		@media (min-width: $small) {
			display: none;
		}
	}
}

.ServiceBox[open] {
	article {
		display: flex;
	}
	.RoundArrowButton {
		transform: translateX(-50%) rotate(180deg);
	}
}

// Themes

.ServiceBox[green] {
	// --theme: #{$color-green};
	border-color: $color-green;
	h3 {
		color: $color-green;
		background: $color-green1;
	}
	h3::after {
		background: $color-green;
	}
	.RoundArrowButton {
		border-color: $color-green;
		path {
			fill: $color-green;
		}
	}
}

.ServiceBox[peach] {
	border-color: $color-peach;
	color: initial;
	h3 {
		color: $color-peach;
		background: $color-peach1;
	}
	h3::after {
		background: $color-peach;
	}
	.RoundArrowButton {
		border-color: $color-peach;
		path {
			fill: $color-peach;
		}
	}
}

.ServiceBox[red] {
	border-color: $color-red;
	color: initial;

	h3 {
		color: $color-red;
		background: $color-red1;
	}
	h3::after {
		background: $color-red;
	}
	.RoundArrowButton {
		border-color: $color-red;
		path {
			fill: $color-red;
		}
	}
}

.ServiceBox[yellow] {
	border-color: $color-yellow;
	h3 {
		color: $color-yellow;
		background: $color-yellow1;
	}
	h3::after {
		background: $color-yellow;
	}
	.RoundArrowButton {
		border-color: $color-yellow;
		path {
			fill: $color-yellow;
		}
	}
}

.flickity-enabled .ServiceBox {
	margin-right: 1rem;
}

.ServiceItem--centered {
	text-align: center;

	.Icon {
		margin-right: 0;
	}
}

.RoundArrowButton {
	width: 2.3rem;
	height: 2.3rem;
	border: 2px solid;
	border-radius: 50%;
	background: white;
}
