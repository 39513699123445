ls-manchet {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 1.25rem 0;
	margin-top: 1rem;
    margin-bottom: 2rem;

    &:last-child {
    	margin-bottom: 0;
	}

	&[left] {
		align-items: flex-start;
	}

	&[vcenter] {
		justify-content: center;
	}

	&[nopadding] {
		padding: 0;

		h2 {
			margin-top: 1rem;
		}
	}

	h1,
	h2,
	h3,
	h4,
 	p	{
		max-width: 45rem;
	}

	h1,
	.h1 {
		text-align: center;
        margin: 0 0 0.5rem;
	}

	h2 {
		margin-top: 0;
		text-align: center;
	}

	p {
		text-align: center;
	}
}
