// Normal, outlined buttons.
.Button {
	display: inline-flex;
	justify-content: space-between;
	padding: 0.5em 1rem 0.4em;
	border: 2px solid;
	border-radius: $border-radius;
	font-size: (16/16) * 1rem;
	font-weight: 600;
	background-color: transparent; //$color-white;
	color: $color-theme;
	text-align: center;
	transition: border-color 200ms, background-color 200ms;
	white-space: nowrap;
	line-height: 1.25;

	&[disabled] {
		opacity: 0.5;
	}
	&[centered] {
		justify-content: center;
	}

	&:hover{
		transition-duration: 125ms;
		background-color: $color-theme;
		color: $color-white;
	}
}

a.Button {
	text-decoration: none;
}

// Buttons with a solid background
.Button--solid {
	background: $color-theme;
	border-color: $color-theme;
	color: $color-white;

	&:hover {
		background: $color-theme-dark;
		border-color: $color-theme-dark;
	}
}

// Buttons without border and background
.Button--text,
a.Button--text {
	text-decoration: none;
	background: none;
	border: 0;

	&:hover {
		background-color: transparent;
		color: $color-theme-dark;
	}
}

// Full-width buttons
.Button--full {
	width: 100%;
	justify-content: center;
}

// Rounder buttons
.Button--round {
	border-radius: $border-radius * 3;
	text-transform: uppercase;
}

// Different colors of buttons

.Button--solid.Button--black {
	background-color: $color-black;
	color: $color-white;
	border-color: transparent;
}

.Button--black {
	border-color: $color-black;
	color: $color-black;

	&:hover {
		background: $color-black;
		color: $color-white;
	}
}

.Button--white {
	// border-color: $color-white;
	background-color: $color-white;
	color: $color-theme;
}

.Button--red {
	border-color: $color-red;
	color: $color-red;

	&:hover {
		border-color: $color-darkred;
		background: $color-darkred;
		color: $color-white;
	}
}
