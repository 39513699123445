.Container {
	width: 100%;
	max-width: $container-width;
}

.Container--small {
	max-width: 28rem;
}

.Container--large {
	max-width: $container-width * 1.5;
}

.Container--superLarge {
	max-width: 85rem;
}
.Container--medium{
	max-width: $container-width * 0.8;
}

.Container--padding {
	padding-left: $container-padding;
	padding-right: $container-padding;
}

.Container--center {
	margin-left: auto;
	margin-right: auto;
}

.Container--end {
	margin-left: auto;
}

.Container--expand {
	min-width: 80vw;
	transform: translateX(-50%);
	position: relative;
	left: 50%;
}

.Container--meetingRoom{
	position: relative;
}
