/* default grid */

$grid-offset: -1rem;

ls-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	padding: 0 1rem;
	margin-bottom: 1.5rem;
}

/* grid sizing */
@media (min-width: $small) {
	ls-grid[columns='auto'] ls-grid-item {
		flex-basis: auto;
	}

	ls-grid,
	ls-grid[columns='2'] {
		ls-grid-item {
			flex-basis: calc(100% / 2);
		}
	}

	ls-grid[columns='3'] {
		ls-grid-item {
			flex-basis: calc(100% / 3);
		}
	}

	ls-grid[columns='4'] {
		ls-grid-item {
			flex-basis: calc(100% / 4);
		}
	}

	ls-grid[columns='5'] {
		ls-grid-item {
			flex-basis: calc(100% / 5);
		}
	}

	ls-grid[columns='6'] {
		ls-grid-item {
			flex-basis: calc(100% / 6);
		}
	}
	ls-grid[columns='7'] {
		ls-grid-item {
			flex-basis: calc(100% / 7);
		}
	}
	ls-grid[columns='8'] {
		ls-grid-item {
			flex-basis: calc(100% / 8);
		}
	}
}

/* Grid variations */
ls-grid[no-padding],
ls-grid ls-grid {
	padding: 0;
}

ls-grid[center] {
	justify-content: center;
}

ls-grid[vcenter] {
	align-items: center;
}

ls-grid[vtop] {
	align-items: flex-start;
}

ls-grid[stretch] {
	align-items: stretch;

	> ls-grid-item {
		display: flex;
		width: 100%;
	}
}

ls-grid[expand] ls-grid-item {
	flex: 1;
}

ls-grid[nowrap] {
	flex-wrap: nowrap;
	scrollbar-width: none;

	display: flex;
	flex-direction: row;
	margin: 0;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		height: 0;
		width: 0;
	}
}

ls-grid[uniform] {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(16em, 1fr));
	grid-gap: 1.5rem;
	// make space for labels
	padding-left: $container-padding;
	padding-right: $container-padding;

	> * {
		margin: auto;
		min-height: 100%;
		padding: 0;
		max-width: 30em;
	}
}

ls-grid[auto] ls-grid-item {
	width: auto;
}

ls-grid[nomargin] {
	margin-bottom: 0;
}

/* Grid item */
ls-grid-item {
	padding: 0.75rem 1rem;
	width: 100%;
}

ls-grid-item[no-padding] {
	padding-left: 0;
	padding-right: 1rem;
}

ls-grid-item[grow] {
	flex-grow: 1;
}

ls-grid-item[center] {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

ls-grid-item[start] {
	justify-content: flex-start;
}

ls-grid-item[border] {
	outline-width: 1px;
	outline-style: solid;
	outline-color: $color-theme;
	outline-offset: $grid-offset;
}

ls-grid-item[background] {
	background-color: $color-gray1;
	// invisible border for spacing
	border: 0.5rem solid $color-white;
}

ls-grid-item[radius] {
	border-radius: $grid-offset * -1 + $border-radius;
}

ls-grid-item[top] {
	align-self: flex-start;
}

ls-grid-item[sticky] {
	position: sticky;
	top: 1rem;
}

.ls-grid--reference img {
	width: 9rem;
}

.ls-grid--product[columns] {
	ls-grid-item {
		max-width: 18rem;
		flex-grow: 1;
		flex-basis: 50vw;
	}
}

.Grid--sidebar {
	@media (max-width: $small) {
		ls-grid-item {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.PressClippings {
	@media (min-width: $small) {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		gap: 1em;
	}
}

.BigGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
	gap: 2rem 0;

	ls-grid-item {
		padding: 0;
	}
}

.BenefitsGrid-item {
	width: 8.2rem;
}

.BenefitsGrid {
	display: flex;
	flex-flow: row wrap;
	grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
	justify-content: center;
	gap: 1rem 0.5rem;
	text-align: center;
	margin-bottom: 2rem;

	h4 {
		font-size: 16px;
		font-weight: 600;
		line-height: 1.2;
		word-break: break-word;
	}

	img {
		width: 2rem;
	}

	&.flickity-enabled {
		.BenefitsGrid-item img {
			width: 3rem;
			margin: auto;
		}
		.BenefitsGrid-item {
			width: 9rem;
		}
	}
}

.Event-grid {
	margin: 25px 10px;

	@media (max-width: 50em) {
		r-grid {
			grid-row-gap: 0;
		}
	}
}

ls-grid[triple]  > * {
	max-width: 28%;
}

.grid123 {
	h4 {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		line-height: 3rem;
		font-size: 24px;
		background: $color-blue;
		color: $color-white;
		margin: 1rem auto;
	}

	h3 {
		margin: 0;
	}

	> * {
		padding: 1rem 1rem 2rem;
		margin-bottom: 0;
		text-align: center;
	}
}
