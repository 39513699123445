.Dropdown {
	position: absolute;
	flex-direction: column;
	width: 16em;
	padding: 0 1rem 1rem;
	background: white;
	box-shadow: 0 60px 100px -20px rgba(50, 50, 93, 0.25);
	max-height: 0;
	padding: 0;
	opacity: 0;
	pointer-events: none;
	transform: translate3d(0, -1rem, 0) scale(0.97);
	overflow: hidden;

	// animation
	transition-property: transform, opacity;
	transform-origin: 50% 0;
	will-change: transform, opacity;
	transition: transform 200ms, opacity 100ms ease-in;

	a {
		width: 100%;
	}
}

@media (max-width: $small) {
	.Nav .Dropdown {
		position: static;
		box-shadow: none;
	}

	.Nav .Dropdown:not(.is-collapsed) {
		max-height: none;
		pointer-events: auto;
		transform: none;
		opacity: 1;
		padding: 0 0 1rem 1rem;
	}
}

@media (min-width: $small) {
	.Nav-item:hover > .Dropdown {
		max-height: none;
		pointer-events: auto;
		transform: none;
		opacity: 1;
		padding: 1rem;
	}
}

// Dropdowns inside breadcrumbs require special styling
.Breadcrumbs .Dropdown {
	top: 1rem;

	// &.is-collapsed {
	max-height: 0;
	padding: 0;
	opacity: 0;
	pointer-events: none;
	overflow: hidden;
	transform: translate3d(0, -1rem, 0) scale(0.97);
}

.Breadcrumbs li:hover .Dropdown {
	max-height: initial;
	padding: 1rem;
	opacity: 1;
	pointer-events: auto;
	transform: translate3d(0, 0, 0) scale(1);
	z-index: 100;
}
