.ContactGrid {
	margin-top: 1rem;
	padding: 1rem;
	background-color: #dff9fc;
	background-image: radial-gradient($color-theme, transparent);

	@media (min-width: $medium) {
		padding: 2rem;
	}

	r-cell {
		display: grid;
		padding: 1rem 1rem 1rem;
		background: white;
		border: 2px solid $color-gray1;
		border-radius: $border-radius;
	}
	figure {
		min-width: 2rem;
		margin: 0 1rem 0 0;
		align-self: flex-start;
		grid-column: 1;
	}
	r-cell > h2,
	r-cell > h3,
	r-cell > p {
		grid-column: 2;
	}
	h3 {
		margin-top: 0;
		margin-bottom: 0.5rem;
		line-height: 1.2;
	}
}

.Contact {
	display: flex;
	justify-content: center;
	padding: 0.5rem;
	background: aliceblue;
	flex-grow: 1;

	@media (min-width: $small) {
		background-color: transparent;
	}
}

.ContactLinks {
	a[href^="mailto"]::before,
	a[href^="tel"]::before {
		// give it a size
		display: inline-block;
		width: 1em;
		// height: 1em;
		// hide text inside
		text-indent: -99em;
		// overflow: hidden;
		margin-right: 0.5em;
	}
	a[href^="mailto"]::before {
		content: "📧 ";
		background: url(/media/uploads/email.svg) no-repeat 0 0.3em;
	}
	a[href^="tel"]::before {
		content: "📞 ";
		background: url(/media/uploads/phone.svg) no-repeat 0 0.2em;
	}
}

.ContactCard {
	display: flex;
}

.ContactCard-media {
	margin: 0 1rem 0 0;
}

.ContactCard-body {
	align-self: center;
	p {
		line-height: 1.7;
	}
	a::before {
		background-position: 0 0.4em !important;
	}
}

.Author {
	display: flex;
	align-items: center;
	img { margin-right: 0.5rem;}
	figcaption {
		font-size: (13/16) * 1rem;
	}
}

