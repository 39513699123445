.Logo {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: $container-padding;
	padding-right: $container-padding;
	position: relative;
	z-index: 5;
	color: $color-text;

	@media (min-width: $small) {
		padding-left: 1.5rem;
	}

	@media (min-width: $large) {
		margin-right: 3rem;
	}
}

.Logo a {
	display: flex;
	align-items: flex-end;
	text-decoration: none;
}

.Logo svg {
	height: 2rem;
	margin-right: 0.75em;
}

.Logo path {
	fill: currentColor;
}

.Logo-title {
	display: none;
	margin: 0;
	font-size: 1rem;
	font-weight: normal;
	color: $color-theme;
}

.Logo.Logo--noMargin {
	margin-right: 0;
}

.Logo--animated,
.Logo:hover {
	animation: logo-blink infinite 500ms;
	animation-delay: 800ms;
	transition: color;
}

.Logo--animatedSlow {
	animation-duration: 2s;
}

@keyframes logo-blink {
	0% {
		color: $color-black;
	}
	16% {
		color: $color-blue;
	}
	32% {
		color: $color-yellow;
	}
	48% {
		color: $color-green;
	}
	64% {
		color: $color-red;
	}
	80% {
		color: $color-peach;
	}
}

.WiwoLogo{
	max-width: none;
	height: 9rem;
	width: 6.875rem;
}
