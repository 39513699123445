.Nav {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding-left: 0;
	flex: 1;
	font-size: (18/16) * 1rem;

	// First level
	.Nav-item > a {
		font-weight: 600;

		@media (max-width: $small) {
			font-size: (20/16) * 1rem;
		}
	}

	@media (max-width: $medium) {
		.Nav-item .Dropdown a {
			font-size: (16/16) * 1rem;
		}
	}

	@media (min-width: $medium) {
		flex-direction: row;
		align-items: center;
	}
}

.Nav--fixed {
	// fixed to top
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;

	// hidden by default
	opacity: 0;
	pointer-events: none;
	transform: translate3d(0, -1rem, 0) scale(0.97);

	@media (min-width: $medium) {
		// no longer fixed or hidden
		position: static;
		opacity: 1;
		pointer-events: auto;
		transform: none;
	}

	// When burger menu has been clicked, show it
	&.is-active {
		flex-flow: column;
		box-shadow: 0 60px 100px -20px rgba(50, 50, 93, 0.25);
		padding: 3.5rem 1rem 0.4rem;
		background: white;
		// animation
		pointer-events: auto;
		transform: translate3d(0, 0, 0);
		opacity: 1;
		min-height: 40vh;
		overflow: auto;
		max-height: 100vh;
	}
}

.Nav-item {
	position: relative;
	overflow: hidden;

	@media (min-width: $medium) {
		margin-left: 1.5vw;
		overflow: visible;

		&:last-child {
			margin-right: 0;
		}
	}

	@media (min-width: $medium) {
		margin-left: 3vw;
	}
}

.Nav a {
	position: relative;
	display: block;
	text-decoration: none;
	color: $color-text;
	padding: 0.3rem;
	transition: border-color 200ms, color 200ms;

	&.is-active {
		color: $color-theme;
	}

	&:hover {
		color: $color-theme;
		transition-duration: 125ms;
	}

	&:hover,
	&.is-active {
		.Nav-dropdownToggle {
			background-image: url('arrow-hover.svg');
		}
	}

	@media (min-width: $medium) {
		padding: 0.5rem;
	}
}

.Nav-item.red a {
	color: $color-red;
}

.Nav--language {
	flex: 0 1 auto;
	margin-left: auto;
	text-transform: uppercase;

	a {
		color: $color-gray2;
	}

	@media (max-width: $medium) {
		margin-top: auto;
	}

	@media (min-width: $medium) {
		margin-right: 1.5rem;

		a {
			font-size: 1rem;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
		}
	}
}

// Small arrow that indicates a hover-dropdown on a nav item.
.Nav-dropdownToggle {
	cursor: pointer;
	padding: 0;
	border: 0;
	text-indent: -999em;
	width: 2.5em;
	position: absolute;
	top: 0;
	right: -0.5rem;
	bottom: 0;
	margin: 0;
	line-height: 1;
	background: transparent url('arrow.svg') no-repeat center;
	// turn the arrow when active
	transition: transform 150ms;
	transform: rotate(-90deg);
	&.is-active {
		transform: rotate(0deg);
	}

	@media (min-width: $medium) {
		position: static;
		width: 1rem;
		transform: rotate(0deg);
	}
}

// Hamburger button that toggles the dropdown on small screens.
.NavToggle {
	margin-left: auto;
	padding-right: $container-padding;
	position: relative;
	z-index: 2;

	@media (min-width: $medium) {
		display: none;
	}
}

.Pagination {
	margin-top: 2rem;
	justify-content: flex-end;
	gap: 0;
	font-size: 0.875rem;

	a {
		color: $color-gray2;
	}

	svg {
		width: 12px;
		height: auto;
	}

	path {
		fill: $color-gray2;
	}

	.active a {
		color: $color-theme;
		font-weight: bold;
		// color: $color-white;
	}
	[aria-label='Previous'] svg {
		margin-right: 0.3em;
	}
	[aria-label='Next'] svg {
		margin-left: 0.3em;
	}
}

.Pagination-item.disabled {
	display: none;
}

.Pagination-item a {
	text-align: center;
	padding: 0 0.4rem;
}
