.Map {
	height: 23vh;
	min-height: 16rem;
	margin-bottom: 1rem;
}

.MapView {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	.Map {
		margin-bottom: 0;
		flex-grow: 1;
	}

	.MapMobile {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		/* above the map */
		z-index: 10000;

		&:hover {
			background-color: $color-gray1;
			cursor: crosshair;
			opacity: 0.7;
		}
	}
}

@media (min-width: $small) {
	.MapView {
		height: 100vh;
		position: sticky;
		top: 4.5rem;
	}

	.MapMobile {
		display: none;
	}
}

.MapView--full {
	height: 100vh;

	.MapMobile {
		display: none;
	}
}

.mapboxgl-popup-content {
	padding: 0;
	font-family: Source Sans Pro, sans-serif;
	font-size: 1rem;

	a {
		text-decoration: none;
	}

	p {
		margin: 0.5rem;
	}

	p:last-child {
		text-align: right;
	}
}

.Map-thumbnail {
	display: block;
}

.mapboxgl-ctrl-group > button:focus {
	border: 1px solid;
}

.mapboxgl-marker > svg {
	transition: transform 100ms;
}

.mapboxgl-marker.is-hovered > svg {
	transition-duration: 400ms;
	transform-origin: bottom center;
	transform: scale(1.5);
}

.MapList-map .Map {
	min-height: calc(100vh - 10rem);
}
