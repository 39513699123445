h1, h2, h3, h4, h5, h6,
p,
span,
div,
figure{
	&[right] {
		text-align: right;
	}
	&[left] {
		text-align: left;
	}

}
