.FancyTitle {
	position: relative;
	z-index: 5;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	font-size: 60px;
	max-width: 20ch;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: $medium) {
		font-size: 5vw;
	}

	span {
		display: block;
	}
}

.FancyCloud {
	position: absolute;
	top: 2%;
	left: 10%;
	right: 0;
	width: 80%;
	height: 18em;
	// z-index: 6;
	// on top of the "userlike" widget, otherwise it looks weird on Safari iOS
	z-index: 21474836470;
	pointer-events: none;
	transform-origin: top center;
	mix-blend-mode: screen;

	&:nth-of-type(1) {
		mix-blend-mode: color-burn;
	}
}

.Fancy-select {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 4rem;
	gap: 1em;
	position: relative;
	z-index: 5;

	.choices {
		min-width: 16em;
	}
	.choices__list--dropdown {
		border: 2px solid $color-blue4;
		border-top: 0;
		margin-top: -7px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
	.choices__inner {
		font-size: 1rem;
	}
	.choices__list--dropdown .choices__list {
		max-height: 320px;
	}
	.choices .choices__inner {
		border-color: $color-blue4;
	}
	.choices__placeholder {
		opacity: 1;
	}
	.choices__list--dropdown .choices__item {
		// font-size: 0.875rem;
		font-size: inherit;

		&:first-child {
			display: none;
		}
	}

}
