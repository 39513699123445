.Note {
	background-color: $color-black;
	color: $color-white;
	// border-radius: 1.875rem;
	// border-bottom-left-radius: 0;
	// border-bottom-right-radius: 0;
	padding: 1rem 2rem;
	position: relative;
	z-index: 1;
	text-align: center;

	ul {
		padding-left: 1rem;
		list-style: disclosure-closed;
	}

	a {
		color: inherit;
		text-decoration: none;
	}
}

.Note--red {
	background: $color-red2;
	color: initial;
}
