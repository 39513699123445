// As of 2021/6 there's not a standard way to color the

price-calculator input[type="range"] {
	--size: 24px;
	--maxWidth: 800px;
	--thumbColor: #fff;
	--trackColorTop: #00b2c2;
	--trackColor: #eee;

	outline: 0;
	border: 0;
	width: var(--maxWidth);
	max-width: 100%;
	transition: box-shadow 0.2s ease-in-out;
	border-radius: var(--size);

	&.alternative {
		--trackColorTop: #{$color-yellow};
	}

	// Chrome
	@media screen and (-webkit-min-device-pixel-ratio: 0) {
			overflow: hidden;
			height: var(--size);
			-webkit-appearance: none;
			background-color: var(--trackColor);

		&::-webkit-slider-runnable-track {
			height: var(--size);
			-webkit-appearance: none;
			transition: box-shadow 0.2s ease-in-out;
		}
		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			width: var(--size);
			height: var(--size);
			cursor: ew-resize;
			background: #fff;
			// box-shadow: -340px 0 0 320px var(--trackColorTop), inset 0 0 0 40px var(--thumbColor);
			box-shadow:
				calc(var(--maxWidth) * -1) 0 0 calc(var(--maxWidth) - calc(var(--size) / 2)) var(--trackColorTop),
				inset 0 0 0 2px var(--trackColorTop);
			border-radius: var(--size);
			transition: box-shadow 0.2s ease-in-out;
			position: relative;
			// top: 1px;
		}
		&:active::-webkit-slider-thumb {
			box-shadow:
				calc(var(--maxWidth) * -1) 0 0 calc(var(--maxWidth) - calc(var(--size) / 2)) var(--trackColorTop),
				inset 0 0 0 4px var(--trackColorTop);
		}
	}

	// Firefox
	&::-moz-range-progress {
		background-color: var(--trackColorTop);
	}
	&::-moz-range-track {
		background-color: var(--trackColor);
	}

	// IE
	&::-ms-fill-lower {
		background-color: var(--trackColorTop);
	}
	&::-ms-fill-upper {
		background-color: var(--trackColor);
	}
}
