select,
optgroup,
input,
textarea,
button {
	font-family: inherit;
	font-size: initial;
}

select {
	height: 2.5rem;
}

select[multiple] {
	min-height: 7.5rem;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='number'],
input[type='datetime'],
input[type='datetime-local'],
textarea {
	padding: 0.6rem;
	// Remove default inner shadow on iOS.
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input[type='date'] {
	min-height: 46px;
}

input[type='time'] {
	min-height: 46px;
}
[type='submit'] {
	margin-top: 0.5rem;
}

textarea {
	width: 100% !important;
	min-height: 5rem;
	padding: 0.7rem;
}

.Form {
	width: 100%;
	position: relative;

	label {
		font-size: 14px;
		display: flex;
		flex-direction: column;
		// margin-bottom: 0.5rem;
	}

	div.choices__inner,
	select,
	textarea,
	input[type='text'],
	input[type='search'],
	input[type='email'],
	input[type='tel'],
	input[type='number'],
	input[type='time'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='date'] {
		margin-top: 0.2rem;
		background-color: white;
		border: 1px solid #a1a4b1;
		border-radius: 4px;
		padding-left: 0.5rem;
		padding-right: 0.5rem;

		&:focus {
			border-color: $color-black;
			outline: none;
		}
	}
}

.js-Form--contact {
	label {
		strong {
			display: block;
			font-weight: normal;
		}
	}

	select {
		width: 100%;
	}
}

.Form--filter {
	display: flex;
	flex-direction: column;
	place-items: center;
	background: white;
	text-align: center;

	select {
		min-width: 300px;
	}
}

.Form-row {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin-bottom: 0.5em;

	> * {
		flex: 1;
	}

	em p {
		margin-bottom: 4rem;
	}
}

.Form-description,
.Form-error {
	margin-top: 0.5em;
	font-size: smaller;
}

.Form-error {
	color: $color-red;

	a {
		color: inherit;
	}
}

.Error-header {
	border-bottom: 1px solid $color-red;
}

.Form-title {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Form-consent {
	font-size: x-small;
	margin-left: auto;
}

.choices .choices__inner {
	min-height: 46px;
	padding: 0.5em 0.6em 0.3em;
	border: 2px solid $color-gray2;
	border-radius: $border-radius;
	background-color: white;
	.choices__input {
		border: none;
		padding: initial;
	}
}

.choices.is-open .choices__inner {
	border-color: #00b2c2;
}

.choices__list--multiple .choices__item {
	margin-bottom: 0;
}
//this one is responsible for the checkbox

.choices[data-type='select-multiple'] .choices__list--dropdown .choices__item::before {
	content: '';
	float: left;
	width: 20px;
	height: 20px;
	border: 1px solid #d7d7d7;
	position: relative;
	top: -3px;
	margin-right: 0.5em;
}

// data-type= "single" not "multiple"?
.choices[data-type='select-multiple'] .choices__list--dropdown .choices__item.is-selected::before {
	border-color: #00b2c2;
	background: #00b2c2;
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.702 1.497L5.87 11.607c-.214.372-.574.593-.963.593-.344 0-.67-.174-.893-.477L.426 6.872a1.019 1.019 0 01.088-1.296.865.865 0 011.207-.013L4.769 8.52 10.223.428c.272-.403.834-.512 1.22-.233.399.289.513.861.259 1.302z' fill='%23fff'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: center;
}

.choices .choices__list--dropdown .choices__item--selectable.is-highlighted {
	background-color: #e5f7f9;
	color: #00b2c2;
}

.choices__heading {
	display: none;
}

// Add a custom dropdown arrow
.choices[data-type*='select-one']::after {
	// .choices[data-type*='select-multiple']::after {
	content: '';
	position: absolute;
	right: 11.5px;
	top: 50%;
	pointer-events: none;
	background-image: url('arrow.svg');
	background-repeat: no-repeat;
	background-position: center;
	// background-color: red;
	width: 13px;
	height: 8px;
	border: 0;
	margin-top: 0;
	transform: translateY(-50%);
	transform-origin: center;
	transition: transform 150ms;
}

// When dropdown is open, turn the arrow upside down.
.choices.is-open[data-type*='select-one']::after,
.choices.is-open[data-type*='select-multiple']::after {
	transform: translateY(-50%) rotate(180deg);
	margin-top: 0;
}

#custom--solid {
	display: flex;
	width: 100%;
	justify-content: center;
}

.Overlays {
	p {
		font-size: 0.75rem;
		color: gray;
		text-align: center;
	}
}

/* Hiding with display: none; disables the "required" attribute.
   So instead we hide it using a more elaborate technique! */
.choices[data-type*='select-one'] .CustomSelect.choices__input {
	display: block !important;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	left: 0;
	bottom: 0;
}

.g-recaptcha {
	display: flex;
	justify-content: center;
	overflow: hidden;
}

#g-recaptcha-response {
	display: block;
	position: absolute;
	width: 302px;
	height: 76px;
	opacity: 0;
}

.choices__list--dropdown {
	transform: translate3d(0, 0, 200px);
}

.Form-row--split > * {
	width: 40%;
}

// 1. hide workspace select everywhere
// 2. on space/single.html, hide city+area but show workspace select
// on team-office.html, no need to do anything
.Form-workspace,
.Form-startingDate {
	display: none;
}

// Hide these two fields by default.
.Form-products,
.Form-comments {
	display: none;
}

// Forms with a workspace
.FormWithWorkspace {
	.Form-city,
	.Form-area {
		display: none;
	}
}

// Single desk form
.SingleDeskForm {
	.Form-city,
	.Form-area,
	.Form-teamSize {
		display: none;
	}
}

.PrivateFloorForm {
	.Form-comments {
		display: block;
	}
	.Form-city,
	.Form-area,
	.Form-teamSize,
	/* hide discount field */
	label[for="00N9W000000QCku"] {
		display: none;
	}
}

.Form p:has(.Button--solid) + p.SmallType {
	margin-top: 0.5rem;
}
