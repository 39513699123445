details:hover summary {
	cursor: pointer;
}

details[open] {}

details[large] {
	summary {
		// like an h3
		font-size: (20/16)*1rem;
		line-height: (48/36);
		padding-left: 0.7rem;
		padding-right: 0.7rem;
	}
}
summary {}

.Details--sticky summary {
	background-color: $color-white;
	position: sticky;
	top: 0;
	z-index: 1;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
}
