video-placeholder {
  display: block;
  /* Contain absolute children */
  position: relative;
  /* iOS fix -> markup needs to be wrapped in a button */
  cursor: pointer;
  /* Hide overflowing child media */
  overflow: hidden;
  /* Black fades better with video embeds */
  background-color: #000;
	margin-bottom: 2rem;
}

/* Ratio trick to ensure a fixed aspect ratio */
video-placeholder::after {
  display: block;
  content: '';
  height: 0;
  padding-top: 56.25%;
  padding-top: calc(9 / 16 * 100%);
}

video-placeholder > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Put first layer on top */
video-placeholder > button {
  border: 0;
  padding: 0;
  background: transparent;
  color: white;
  z-index: 1;
  /* the 16ms is to give the embed a chance to show before showing it */
  transition: opacity 200ms 16ms cubic-bezier(0.65, 0.05, 0.36, 1);
}

/* Make any front images fill the container */
video-placeholder > button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Hide first child, when it is open */
video-placeholder[open] > button {
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms 16ms cubic-bezier(0.65, 0.05, 0.36, 1), visibility 0s 300ms;
}


.PlayButton {
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
	max-width: 80px;
	max-height: 80px;
	margin: auto;
	transition: transform 200ms cubic-bezier(0.65, 0.05, 0.36, 1);
}

video-placeholder > button:focus .PlayButton {
	transform: scale(1.2);
}
