price-calculator {
	display: block;

	h2 {
		margin-bottom: 0;
	}

	.Split h2 {
		margin-top: 0;
	}

	input[type="range"] {
		width: 100%;
		margin-top: 0.6rem;
	}
}

price-calculator.Box {
	padding-top: 1px;
}

.Warning-message {
	// for small display

	position: relative;
	margin-bottom: 0.875rem;
	border: 2px solid $color-yellow;
	border-radius: 0.5rem;
	text-align: left;
	font-size: 0.875rem;
	padding: 0.7rem 0.7rem 0.7rem 1.4rem;
	max-width: 18rem;
	transition: opacity 200ms;
	opacity: 0;
	display: none;

	// for large display
	@media (min-width: 873px) {
		display: block;
		visibility: hidden;
		position: absolute;
		top: 1.875rem;
		right: 1rem;
	}
}

.Message-content {
}

.Warning-message::before {
	position: absolute;
	top: 50%;
	left: -1px;
	height: 1.75rem;
	display: block;
	content: "!";
	font-size: 1.25rem;
	line-height: 1.2;
	transform: translate(-50%, -50%);
	color: $color-white;
	background-color: $color-yellow;
	border-radius: 50%;
	border: 2px solid $color-white;
	width: 1.75rem;
	text-align: center;
}

.Warning-message.visible {
	display: block;
	visibility: visible;
	opacity: 1;
}
