.Icon {
	height: 1.5rem;
	margin-right: 1rem;
}

.Icon--large {
	height: 2rem;
}

.Icon.currency-exchange {
	background-position: -0px -0px;
}

.Icon--huge {
	height: 4rem;
	margin-bottom: 0.5rem;
}

.ScrollUpButton {
	display: flex;
	width: 40px;
	height: 40px;
	background: hsla(0deg, 0%, 100%, 0.25);
	border-radius: 50%;

	position: fixed;
	right: 2rem;
	bottom: 1rem;
	z-index: 1;

	transition: background 200ms, opacity 200ms;

	img {
		margin: auto;
	}

	&:hover {
		background: hsla(0deg, 0%, 100%, 0.6);
	}

	&.is-hidden {
		opacity: 0;
		pointer-events: none;
	}
}
