.Table-row {
	display: flex;
	flex-flow: row nowrap;
	font-size: small;
	border-bottom: 1px solid $color-gray2;
	gap: 1em;
}

.Table-row--header {
	font-weight: bold;
	text-transform: uppercase;
}

.Table-row:nth-child(even) {
	background: $color-gray00;
}

.Table-row:not(.Table-row--header):hover {
	background: $color-yellow;
}

.Table-cell {
	padding: 0.25em 0;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 1em;
	word-wrap: anywhere;
}

.Table-cell:nth-child(1) {
	flex: 0 1 11em;
}
.Table-cell:nth-child(2) {
	flex: 0 1 4em;
}
.Table-cell:nth-child(5) {
	flex: 0 1 17em;
}
.Table-cell:nth-child(6) {
	flex: 0 1 5em;
}
.Table-cell:nth-child(7),
.Table-cell:nth-child(8),
.Table-cell:nth-child(9),
.Table-cell:nth-child(10) {
	flex: 0 1 4em;
}

.Table-cell a {
	color: $color-black;
	font-weight: bold;
	text-decoration: none;
}



.Table--responsive {
	overflow: auto;
	min-width: 1400px;
}
