.Testimonials {
	position: relative;
	flex-wrap: wrap;
}

.CardBox,
.Testimonials-card {
	border-radius: 1.5rem;
	background-color: $color-white;
}

.CardBox {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	padding: 2em 1em;
	text-align: center;

	p,
	h2 {
		margin: 0;
	}

	h2 {
		font-size: 3rem;
	}
}

.Testimonials-card {
	padding-top: 3rem;
	border: 1px solid $color-gray1;
	min-height: 17rem;
	width: 24rem;
	margin: 0 0.5rem;
	padding: 2rem 2rem 0;

	a {
		color: $color-blue;
	}
}

.Testimonials-header {
	display: flex;
	margin-bottom: 0.5rem;

	.Testimonials-logo {
		align-self: baseline;
		margin-left: auto;
	}
}
