.Site {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: 0;
}

.Site-header {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	min-height: 3rem;
	z-index: 8;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transform: translateY(0%);
	transition: transform 200ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
	background-color: $color-white;
	background: hsla(0, 0%, 100%, 0.97);
	box-shadow: 0 0 2px hsla(0, 0%, 0%, 0.5);

	&.is-goingdown {
		transition-duration: 400ms;
		transform: translateY(-100%);
	}

	&.is-goingup.is-below {
		transform: translateY(0%);
	}
}

.Site-main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	// move below fixed header
	margin-top: 5rem;
}

.Site-main--map {
	margin-top: 0;
	margin-bottom: 0;
}

.Site-footer {
	position: relative;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background: $color-black;
	color: white;
}

ls-scrollable {
	overflow: hidden;
	display: flex;
}

.no-underline {
	text-decoration: none;
}

.Sticky {
	position: sticky;
	top: 0;
	z-index: 9;
}

.StickyMobileCTA {
	position: fixed;
	top: auto;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0.5rem 1.25rem;
	transition:
		opacity 200ms,
		transform 200ms;
	background: white;

	@media (min-width: $small) {
		display: none;
	}

	&.is-hidden {
		opacity: 0;
		transform: translateY(100%) scale(0.7);
	}
}

.Blobs {
	// background: url('background-blobs2.svg');
	background: url('/media/blob.svg');
	background-size: 100%;
	background-position: 0 6rem;
	background-repeat: no-repeat;
	border-bottom: 5px solid $color-theme;
}

// Theme
.theme-office {
	--theme: #{$color-theme};
}
.theme-event-spaces {
	--theme: #{$color-red};
}
.theme-meeting-rooms {
	--theme: #{$color-green};
}
.theme-private-floors,
.theme-single-desks {
	--theme: #{$color-peach};
}

// Theming for product pages
.Product {
	h1 {
		color: var(--theme);
	}
	hr {
		background-color: var(--theme);
		margin: 0.9rem auto 1.7rem;
	}
}

.Site-main:has(.ArticleHeader--large) {
	margin-top: 3rem;
}

.ArticleHeader--large {
	background: $color-blue1;
	margin-bottom: 1rem;

	&.theme-peach {
		background-color: #{$color-peach1};
		h1,
		.Button {
			color: #{$color-peach3};
		}
		.Button:hover {
			background: white;
		}
	}
	&.theme-darkred {
		background-color: #{$color-darkred};
		color: $color-white;
	}
	&.theme-black {
		background-color: #{$color-black};
		color: $color-white;
	}

	.Container {
		display: flex;
		flex-flow: column nowrap;
		max-width: none;
		padding: 0;
		text-align: center;
	}

	h1,
	h2,
	p {
		padding-left: 1rem;
		padding-right: 1rem;
		margin: 0 auto;
		max-width: 60rem;
	}
	h1 {
		color: $color-green;
	}

	figure {
		order: -1;
		margin: 0 0 3rem;
	}

	img {
		width: 100%;
		/* limit in viewport height */
		max-height: 80vh;
		object-fit: cover;
		object-position: center;
	}

	p {
		font-size: larger;
		margin-top: 1rem;
		margin-bottom: 3rem;
		max-width: 90ch;
		text-wrap: pretty;
	}


	p:has(.Button) {
		margin-bottom: 3rem;
	}
}

.SpecialOffer {
	margin-bottom: 2em;
	padding-top: 1.2em;
	padding-bottom: 1.2em;
	background: $color-peach;
	a {
		color: $color-white;
		text-decoration: none;
	}
}

.typeform-widget iframe {
	// Remove margin from typeform iframe embeds on our blog.
	margin: 0;
}

.SiteBackground {
	background: #dafbff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	z-index: -1;
}

// Remove margin from the Netlify deploy preview feedback thing.
[data-netlify-deploy-id] iframe {
	margin: 0;
}

.AppLinks {
	display: grid;
	gap: 1em;
	grid-template-columns: 1fr;
	@media (min-width: $small) {
		grid-template-columns: repeat(auto-fit, 170px);
	}

	a {
		border: 2px solid $color-black;
		border-radius: 0.4em;
		padding: 0.3em;
		display: flex;
	}
	img {
		display: block;
		height: 2em;
		margin: auto;
	}
}

.KeyFacts {
	padding: 0;
	display: flex;
	flex-flow: row wrap;
	text-align: center;

	li {
		flex-basis: 50%;
		list-style: none;
		margin-bottom: 1rem;
		@media (min-width: $small) {
			flex-basis: 25%;
		}
	}

	p:last-child {
		display: flex;
		justify-content: center;
	}

	img {
		margin-top: 0.5rem;
	}
}

.Dot {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background: $color-theme;
	color: white;
	border-radius: 28px;
	font-weight: bold;
	font-size: 24px;
}

[center] .Dot {
	margin: auto;
}

.FlexCenter {
	display: flex;
	flex-direction: column;
	place-items: center;
	place-content: center;
}

.FullFigure img {
	width: 100%;
}

figure.Split {
	// width: 100%;
	display: flex;
	gap: 1.5rem;

	> * {
		flex: 1;
	}

	img {
		width: 100%;
	}

	figcaption {
		text-align: right;
		font-size: small;
	}
}

figure img {
	display: block;
}

[hidden] {
	display: none;
}

.Article--fullWidth {
	.Container {
	}
}

blockquote p {
	font-style: italic;
}
blockquote cite {
	font-style: normal;
}

.MediaBlock {
	blockquote {
		margin: 0;
	}

	img {
		width: 100%;
	}

	p {
		font-size: larger;
	}

	@media (min-width: $small) {
		display: flex;
		flex-flow: row nowrap;

		> img {
			order: 2;
			width: 50%;
			object-fit: cover;
		}
	}
}

.Section:has(>.MediaBlock) {
	padding-top: 0;
	padding-bottom: 0;
	h2 {
		margin-top: 3rem;
	}
	p {
		margin-bottom: 2rem;
	}
}

@media (min-width: $small) {
	.MediaBlock--reverse img {
		order: 0;
	}
}

.MediaBlock-inner {
	text-align: center;
	padding: 0 1rem 2rem;

	@media (min-width: $small) {
		padding-left: 2rem;
		padding-right: 2rem;
		text-align: left;
		display: flex;
		flex-flow: column;
		margin: auto;
		max-width: 45rem;
	}
}

.IconList {
	text-align: center;

	h4::before {
		display: block;
		content: '';
		width: 3rem;
		height: 3rem;
		margin: 0 auto 0.3rem;
		background: url(/media/uploads/check.svg) no-repeat center;
	}

	@media (min-width: $small) {
		max-width: 45rem;
		margin: 0 auto;
		text-align: left;

		.Gallery > div {
			margin-bottom: 1.5rem;
		}

		h4::before {
			vertical-align: top;
			float: left;
			margin: 0 1rem 0 0;
			min-height: 6rem;
			clear: left;
			background-position: top center;
		}
	}
}

.IconList,
.IconList2 {
	h4 {
		margin-bottom: 0.3rem;
	}
}

.IconList2 {
	text-align: center;
	display: flex;
	flex-flow: column nowrap;
	gap: 1rem;

	article {
		max-width: 300px;
		margin-right: 2rem;
	}

	img {
		max-width: 3rem;
		margin: 0 auto 0.3rem;
	}
}
