@property --angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}

.AppleGrid-box--green {
	background-color: $color-green1;
}
.AppleGrid-box--blue {
	background-color: $color-blue1;
}
.AppleGrid-box--red {
	background-color: $color-red1;
}
.AppleGrid-box--yellow {
	background-color: $color-yellow1;
}
.AppleGrid-box--peach {
	background-color: $color-peach1;
}

.AppleGrid {
	--border: #e1e1e1;

	+ .ThumbGallery {
		margin-top: 1.5rem;
		margin-bottom: 1rem;
	}

	img {
		width: 40px;
	}

	h3,
	p {
		margin: 0;
	}
	p {
		line-height: 1.2;
		@media (min-width: $small) {
			font-size: 20px;
		}
	}
	h3 {
		font-size: 30px;
		@media (min-width: $small) {
			font-size: 30px;
		}
	}

	h3,
	p:not(:first-of-type) {
		display: none;
		@media (min-width: $small) {
			display: block;
		}
	}

	> div {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0.4rem;
		margin-bottom: 0rem;
		border-radius: 1rem;

		@media (max-width: $small) {
			// overwrite bg from .Section--....
			background-color: transparent;
			gap: 0.5rem;
		}
	}

	> div:not([class]) {
		background-color: $color-white;
	}

	@media (min-width: $small) {
		display: flex;
		gap: 1rem;
		flex-flow: row nowrap;

		// &.four {
		// 	grid-template-columns: repeat(4, 1fr);
		// }
		// &.three {
		// 	grid-template-columns: repeat(3, 1fr);
		// }

		> div {
			flex: 1;
			text-align: center;
			border: 1px solid var(--border);
			padding: 3rem 1rem;
			flex-flow: column;
			margin-bottom: 1rem;
		}

		> div::before,
		> div::after {
			content: '';
			// mix-blend-mode: soft-light;
			position: absolute;
			inset: -0.2rem;
			z-index: -1;
			opacity: 0;
			border-radius: 1rem;
			background: linear-gradient(var(--angle), $color-theme, #c3f2ff, white);
			will-change: transform;
			transition: all 300ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
		}
		> div::after {
			filter: blur(10px);
		}

		> div:hover::before,
		> div:hover::after {
			transition-duration: 800ms;
			opacity: 1;
			animation: rotate 5s 200ms linear infinite;
		}

		> div:not([class]) {
			&::before,
			&::after {
				background: linear-gradient(var(--angle), $color-theme, #c3f2ff, white);
			}
		}

		> .AppleGrid-box--pink {
			&::before,
			&::after {
				background: linear-gradient(var(--angle), $color-red2, #f9c3ff, $color-red1);
			}
		}

		> .AppleGrid-box--blue {
			&::before,
			&::after {
				background: linear-gradient(var(--angle), $color-blue1, #f9c3ff, $color-blue2);
			}
		}

		> .AppleGrid-box--yellow {
			&:hover {
				background-color: transparent;
			}
			&::before,
			&::after {
				background: linear-gradient(var(--angle), $color-yellow1, #f9c3ff, $color-yellow);
			}
		}

		@keyframes rotate {
			0% {
				--angle: 0deg;
			}
			100% {
				--angle: 360deg;
			}
		}

		> div:hover {
			// border-color: black;
			// background-color: transparent;
		}
		> div:hover::after {
			opacity: 1;
			transform: translate3d(0, 0, 0) scale(1);
			// transition: transform 0.8s;
		}

		img {
			width: 60px;
			margin-bottom: 0.5em;
		}

		p:first-of-type {
			display: none;
		}
		h3,
		p:not(:first-of-type) {
			display: block;
		}
	}
}

.SpaceGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));

	@media (max-width: $small) {
		display: none;
	}

	> div {
		position: relative;
	}

	a {
		display: block;
		padding: 1rem;
	}

	a::after {
		content: '';
		background: $color-green1;
		position: absolute;
		top: -0.7rem;
		left: 50%;
		width: 10rem;
		height: 10rem;
		z-index: -1;
		border-radius: 99999px;
		transform: translate3d(-50%, 0, 0) scale(0);
		transition: transform 600ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
	}

	a:hover::after {
		transform: translate3d(-50%, -0.5rem, 0) scale(1);
		transition: transform 0.2s;
	}
}

.LogoWall {
	margin-bottom: 2rem;

	.Gallery {
		img {
			width: calc(100% / 3);
			height: 3rem;
			object-fit: contain;
			object-position: center;
			padding: 0 0.5rem;
		}

		@media (min-width: $small) {
			justify-content: center;

			img {
				max-width: 5rem;
				max-height: 2rem;
				padding: 0;
			}
		}
	}

	.flickity-prev-next-button.previous {
		left: -1rem;
	}

	.flickity-prev-next-button.next {
		right: -1rem;
	}
}

.ImageWithTestimonial {
    position: relative;
    margin-bottom: 13rem;

    @media (min-width: $small) {
        margin-bottom: 2rem;
    }
}

.Testimonial {
    position: absolute;
    left: 5%;
    right: 5%;
    top: 80%;
    background: white;
    padding: 1rem;

    img {
        width: 52px;
        float: right;
    }

    p {
        font-size: 14px;
    }

    @media (min-width: $small) {
        top: auto;
        left: 1rem;
        bottom: 1.5rem;
        right: auto;
        max-width: 300px;
    }
}
