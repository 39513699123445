// https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css

.cc-window {
	position: fixed;
	bottom: 4rem;
	z-index: 10001;

	display: flex;
	flex-flow: column nowrap;
	place-items: center;
	width: 90%;
	max-width: 50rem;
	padding: 7rem 2rem 2rem;
	background: white 50% 1.5rem url("media/uploads/cookie.svg") no-repeat;
	color: $color-text !important;
	border: 1px solid $color-gray1;
	border-radius: $border-radius * 2;
	box-shadow: 0 3px 7px 1px hsla(0, 0%, 0%, 0.1);

	opacity: 1;
	transition: opacity 1s ease, transform 400ms ease-out;

	left: 50%;
	transform: translateX(-50%);
	transition-duration: 200ms;
}
.cc-link {
	padding: 0;
}
.cc-compliance {
	display: flex;
	flex-flow: row nowrap;
	margin-top: 2rem;
}
.cc-deny {
	order: 2;
}
.cc-window .Button--round {
	text-transform: none;
}

.cc-window.cc-invisible {
	opacity: 0;
	transform: translateX(1em);
}

.cc-revoke:hover {
	transform: translateY(0);
}

@media print {
	.cc-revoke,
	.cc-window {
		display: none;
	}
}

// Hide button to revoke.
.cc-revoke {
	display: none;
}

@media (min-width: $small) {
	.cc-window {
		flex-direction: row;
		padding: 2rem 1rem 2rem 7rem;
		background-position: 2rem 50%;
	}
	.cc-message {
		margin-right: auto;
		padding-right: 1rem;
	}
	.cc-compliance {
		margin-top: 0;
	}
}
